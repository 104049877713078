
{#if show}

    <Fab position="right-bottom" text={title} class={'fab-bot ' + fabBot} style="--text-color:{textColor}; --fab-back-color:{color}"  bind:this={fab}>
        {#if imgSrc != null}
          <img src={imgSrc} width="60px" alt="" />
        {:else}
          <Icon material={materialIcon} size="32" />
        {/if}
         <FabButtons position="top">
            {#each buttons as b,i}  
             <FabButton label={b.label} on:click={()=> clickButton(i)} fabClose>
              <Icon material={b.iconMaterial} size="24" />
             </FabButton>
            {/each}
         </FabButtons>
    </Fab>
   
{/if}
<style>
:global(.fab-bot .fab-text){
  color: var(--text-color);
}
</style>
<script>
    import {
    f7,f7ready,Fab,FabButtons,FabButton,Icon,Popover,Link,Button,FabBackdrop
  } from 'framework7-svelte';
  import { onMount,createEventDispatcher  } from 'svelte';
   import {css} from 'emotion/dist/emotion.umd.min.js';
  export let materialIcon,color="green",transparent=true;
  export let show=false;
  export let buttons=[];
  export let imgSrc=null;
  export let title;
 export let textColor="#000"

let dynamicPopover;

 

onMount(() => {
    f7ready(() => {
     

    });
});
 
  let fab;
  const fabBot= css`
    z-index: 9999; bottom: 25px; 
    a {
        opacity: 0.7;
        background: var(--fab-back-color) !important;
    }
  `;
//background: transparent !important;
//
const dispatch = createEventDispatcher();
function clickButton(i){
    console.log("--click")
    dispatch('buttonClick',{b: i});
}
</script>
