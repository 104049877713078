<!-- Page content -->
  <Popup class="b-popup" on:popupOpened={bPopupOpened} onPopupClosed={() => popupBotOpened = false} opened={popupBotOpened}>
     <Page messagesContent={true} withSubnavbar>
          <Navbar title='Chat'>
            <NavRight>
              <Link popupClose>Close</Link>
            </NavRight>
            <div class="subnavbar">
              <div class="subnavbar-inner">
                <div class="title">
                  <img src={botAvatar} width="40" alt="">
                  <span>&nbsp;</span>
                  <span>{botName}</span>
                </div>
              </div>
				    </div>
          </Navbar>
            
              <Messagebar
          placeholder={placeholder}
          bind:this={messagebarComponent}
          value={messageText}
          onInput={(e) => messageText = e.target.value}
        >
        <a href="#ms" class="link icon-only" slot="inner-end" on:click={sendMessage}>
            <Icon
              ios="f7:arrow_up_circle_fill"
              aurora="f7:arrow_up_circle_fill"
              md="f7:arrow_up_circle_fill"
            />
          </a>
        </Messagebar>
      <PhotoBrowser photos={photos} theme="dark" bind:this={standaloneDark} />
  
      <Messages>
        <MessagesTitle><b>Monday, August 26th</b> 12:58</MessagesTitle>
        {#each messagesData as message, index (index)}
          {#if message.text || message.image}
          <Message
            type={message.type}
            image={message.image}
            name={message.name}
            avatar={message.avatar}
            first={isFirstMessage(message, index)}
            last={isLastMessage(message, index)}
            tail={isTailMessage(message, index)}
            htmlText={message.text}
            on:click={clickBubble(message.imageIdx)}
          />
          {:else}
            {#if message.buttons}
              <Block><Row>
              {#each message.buttons as btn}
                <Col width="90"><Button on:click={doSuggestion(`${btn}`)} outline class="buttonSuggest">{btn}</Button></Col>
              {/each}
              </Row></Block>
            {/if}
          {/if}
        {/each}
        {#if typingMessage}
          <Message
            type="received"
            typing={true}
            first={true}
            last={true}
            tail={true}
            header={`${typingMessage.name} is typing`}
          ></Message>
        {/if}
      </Messages>
 
     </Page>
  </Popup>
<style>
.subnavbar .title:first-child {
    margin-left: 0;
}
.subnavbar .title img {
    float: left;
    margin-top: 4px;
}
.subnavbar .title {
  line-height:48px;
}
</style>
<script>
import { f7,f7ready, Page,Navbar, Row,Col,NavRight,Button,Messages,Message,Messagebar,MessagesTitle,hotoBrowser, PhotoBrowser,Icon, Block,Popup,Link} from 'framework7-svelte';
import { onMount, onDestroy,tick } from 'svelte';
import {initStart,sendToBot } from './dts-shared-components/chatbot/scAPI.js'
let messagebarComponent;
let messagebarInstance;
let messageText='';
let messagesData=[];
let typingMessage = null;
let responseInProgress = false;
let standaloneDark;
let photos=[];
let updated=true;
let placeholder=`How can I help you?`
let voices = window.speechSynthesis.getVoices();
let started=false,response=null;
let popupBotOpened=false;
let session='';
let logging=false;
export let botName="Chatbot";
export let botLang="en-US";
export let userName="";
export let botAvatar;
export let botVoice=6; //0=male,4=female,5=male,6=female spanish
export let cmodule="restaurant",action="welcome";
export let suggest;
export let lid="";
export let uid="";



onMount(() => {
    f7ready(() => {
      messagebarInstance = messagebarComponent.instance();
        if (!started){
          typingMessage={
              name: botName
          }
          // TODO: Lookup fav alcohol list based on uid, store in context
          initStart(cmodule,action,userName,lid,uid,"",true)
          .then((r)=>{
                    started=true;
                    session=r.session;
                    response=r;
            });
         }
    });
});


$: {
  if (response) {
      typingMessage = null;
      tick().then(()=>{
          renderResponse(response)
      })
  }
   if (suggest){
     if (!started){
      window.setTimeout(()=>{
          if (suggest)
            doSuggestion(suggest)
            suggest=null;
      },2500)
     }else{
         doSuggestion(suggest)
         suggest=null;
     }
  
   }
 // if ($position){
 //   console.log("--Position updated?--")
 // }

}


 function sendMessage(){
    let text = messageText.replace(/\n/g, ' ').trim();
    //let text=messageText.trim();

    //text=text.replace(/[^\x00-\x7F]/g, "");
    if (text.length){
        messagesData=[...messagesData,{
       text: text,
       type: 'sent'
      }];
      typingMessage={
          name: botName
      }
      tick().then(()=>{
        messageText='';
        sendToBot(cmodule,session,text).then((r)=>{
        // Update response will trigger UI
        response=r;
         })
   
      })
    }
}

/*************************************************************************/
/* renderResponse
***************************************************************************/
function renderResponse(resp){
var d = new Date();
let time=d.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");

    resp.result.forEach(msg => {
      if (logging == "true") console.log(msg);
          
      if (msg.message == 'text'){
        messagesData = [...messagesData, {
          text: msg.text.text,
          type: 'received',
          name: botName,
          t:time,
          buttons:null,
          imageIdx:-1,
        }];
        speakIt(msg.text.text[0])
      }else{
      //	let replyButtons='';
        if (msg.message == 'quickReplies'){
           messagesData = [...messagesData, {
          type: 'received',
          name: botName,
          t:time,
          buttons:msg.quickReplies.quickReplies
        }];
        }else{
          if (msg.message == 'card'){
            photos=[...photos,{url:msg.card.imageUri, caption: msg.card.title}]
               
              messagesData = [...messagesData, {
              type: 'received',
              name: botName,
              t:time,
              text: msg.card.title,
              image:msg.card.imageUri,
              imageIdx: photos.length-1,
            }];
            speakIt(`Here is a ${msg.card.title}`)
            
          }
        }
      //	}
      }
    });
    response=null;
}
function doSuggestion(txt){
    let stripText=txt.replace(/[^\x00-\x7F]/g, "");
    if (stripText.length){
      messagesData=[...messagesData,{
       text: stripText,
       type: 'sent'
      }];
      typingMessage={
          name: botName
      }
      tick().then(()=>{
        sendToBot(cmodule,session,stripText).then((r)=>{
        // Update response will trigger UI
        response=r;
         })
   
      })
    }
}

 function fabSuggest(){
    doSuggestion('Suggest a drink');
  }
   function fabLucky(){
    doSuggestion('Surprise me!')
  }
  function fabPopular(){
    doSuggestion('Popular Drinks')
  }
function speakIt(text){
    //console.log("speakIt",text)
    let speech = new SpeechSynthesisUtterance(text)
    voices = window.speechSynthesis.getVoices();
    if (voices.length){
      speech.voice = voices[botVoice];
      speech.voiceURI='native'
      speech.lang=botLang;
      window.speechSynthesis.speak(speech);
    }
}
function isFirstMessage(message, index) {
    const previousMessage = messagesData[index - 1];
    if (message.isTitle) return false;
    if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) return true;
    return false;
}
function isLastMessage(message, index) {
    const nextMessage = messagesData[index + 1];
    if (message.isTitle) return false;
    if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
    return false;
  }
function isTailMessage(message, index) {
    const nextMessage = messagesData[index + 1];
    if (message.isTitle) return false;
    if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
    return false;
}
function clickBubble(bi){
  if (photos.length){
    if (bi > -1)
      standaloneDark.open(bi);
  }
}
function bPopupOpened(){
  popupBotOpened=true;
  console.log("opened!")

}



</script>