<App params={ f7params } >

  <!-- Left panel with cover effect-->
  <Panel left cover>
    <View>
      <Page>
        <Navbar title="Left Panel"/>
        <Block>Left panel content goes here</Block>
      </Page>
    </View>
  </Panel>


  <!-- Right panel with reveal effect-->
 
    {#if $user}
   <div class="panel panel-right panel-reveal">
    <View>
      <Page noNavbar>  
        <div class="panel-header">
            <img src={$user.photoURL} alt="" style="border-radius:50%" width="80px" />
            <p>{$user.displayName}</p>
        </div>
        <Block>Right panel content goes here</Block>
      </Page>
    </View>
    </div>
     {/if}
<!---
      <Link tabLink="#view-dummy" popupOpen=".b-popup" iconIos="f7:chat_bubble_text" iconAurora="f7:chat_bubble_text" iconMd="material:chat" text="Ask Bar Bot" />
-->

    <!-- Views/Tabs container -->
  <Views tabs class="safe-areas">
    <!-- Tabbar  -->
    {#if $comingSoon == false} 
    <Toolbar tabbar labels bottom class="main-toolbar" noShadow>
      <Link tabLink="#view-home" tabLinkActive iconIos="material:local_bar" iconAurora="material:local_bar" iconMd="material:local_bar" text="Menu" />
      <Link tabLink="#view-explore" iconIos="material:search" iconAurora="material:search" iconMd="material:search" text="Explore" />
      <Link tabLink="#view-alerts" text="Orders">
        <NotificationBell color="white" pageName="venue" notifColor="red" icon="notifications"></NotificationBell>
      </Link>
      <Link tabLink="#view-favorites" iconIos="f7:heart_circle" iconAurora="f7:heart_cirle" iconMd="f7:heart_circle" text="Favorites" />
      <Link tabLink="#view-more" iconIos="f7:ellipsis" iconAurora="f7:ellipsis" iconMd="f7:ellipsis" text="More" />
    </Toolbar>
   {/if}
    <!-- end Toolbar -->

    <!-- Your main view/tab, should have "view-main" class. It also has "tabActive" prop -->
    {#if $comingSoon}
     <View id="view-home" name="main" main tab tabActive url="/coming/" />
    {:else}
     <View id="view-home" name="main" main tab tabActive url="/" />
    {/if}
    <!-- Catalog View -->
    <View id="view-explore" name="exploreview" tab url="/explore/" />
 <View id="view-favorites" name="history" tab url="/favorites/" />
 <View id="view-alerts" name="alerts" tab url="/alerts/" />
 <View id="view-more" name="more" tab url="/more/" />

  </Views>

  <Location auto show={false} titlePopup="Just Drinks!" whyLocation="Just Drinks! uses location information to help you find, and order drinks."></Location> <!-- gps location services -->
  <FireBase config={firebaseAppConfig} geoFirestore={true} loadScripts={true}></FireBase>
  <Login title="Unlock the full Just Drinks experience!" appUrl={appUrl} on:loggedIn={loggedIn} userAnonymous={true} required={false}>
    <div slot="headingContent">
      <BlockTitle>It's easy and free!</BlockTitle>
      <ul>
        <li>Order drinks from your table at participating venues.</li>
        <li>Add drinks to favorites for future ordering</li>
      </ul>
    </div>
  </Login>
  <Notification messageKey="BGWpIlpjaWL5hrHpIq9Sb6NQ5UCUDhEFaMsOAoAPXcBYCrXycgxfbmnaIj4kuBss5aGepR_A-sICoddr0mZtrU4"></Notification>

</App>
<style>
	.panel-header {
				align-items: center;
				background-image: linear-gradient(-20deg, var(--oc-color-yellow-5) 0%, var(--oc-color-yellow-8) 100%);
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				height: 144px;
				justify-content: center;
				padding: 16px;
				text-align: center;
				width: 100%;
			}
</style>
<script>
  import { onMount,tick } from 'svelte';
  import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
  import {firebaseAppConfig} from '../js/firebase-config' // App firebase info
import FireBase from '../components/dts-shared-components/firebase/firebase.svelte'
import Notification from '../components/dts-shared-components/notification/notification.svelte'
import NotificationBell from './dts-shared-components/notification/bell.svelte'
import Login from '../components/dts-shared-components/auth/login.svelte';
import Logout from '../components/dts-shared-components/auth/logout.svelte';
import Location from '../components/dts-shared-components/location/location'
 import logo from '../static/icons/logo2-96.png'
import {place,places,locInitialized,hideMainToolbar,loadingData,appInitialized,userInfo,comingSoon} from '../js/store'
import {gotPosition,position,requestPermission} from './dts-shared-components/location/store'
import {firestoreInstance,firebaseLoaded,GeoFirestoreLoaded} from './dts-shared-components/firebase/store'
import {notifications, gotNavPermission,messaging,messagingToken} from './dts-shared-components/notification/store'
import {user} from './dts-shared-components/auth/store'

import {
    f7,f7ready,App,
    Panel,Views,
    View,Popup,Page,
    Navbar,Toolbar,
    NavRight,Link,Block,
    BlockTitle,LoginScreen,LoginScreenTitle,
    List,ListItem,ListInput,ListButton,BlockFooter,FabBackdrop
  } from 'framework7-svelte';

/*
$: if ($messaging){
   if ($gotNavPermission){
      $messaging.requestPermission()
          .then(()=>{
            return $messaging.getToken();
          })
          .then((token)=>{
              messagingToken.set(token)
          })
          .catch(()=>{

          })
   }
}
*/

import {initializeI18n} from '../js/core'

import cocktailIcon from '../assets/icons/cocktail-icon.png'
import beerlIcon from '../assets/icons/beer-icon2.png'

  

  import cordovaApp from '../js/cordova-app';
  import routes from '../js/routes';

  let appUrl="https://justdrinks.app/";

   if(window.location.href.indexOf("localhost") > -1) {
     appUrl="http://localhost:5000/";
   }
 
let showBot;

$: {
  if ($firebaseLoaded) {
    appInitialized.set(true);
  }
}


// Any events found from background service?
 if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', function(event) {
          let p=event.data;
          let notifs=$notifications;
          let obj={id: p.data.id,pic: p.data.image,title: p.data.title + "sw",msg: p.data.body,time:new Date()};
          notifs.unshift(obj);
          notifications.set(notifs);
      });
  }

  /* Handle chqange of user info */
  $: if ($userInfo){
   f7ready(()=>{
    if ($userInfo.themeDark)
        f7.$("html").addClass('theme-dark');
      else
        f7.$("html").removeClass('theme-dark');
   })
  
}


  // Framework7 Parameters
  let f7params = {
    id: 'cloud.dtown.justdrinks', // App bundle ID
    name: 'Just Drinks!', // App name
    theme: 'md', // Automatic theme detection
    rtl:false,
    // App root data
    data: function () {
      return {
        gotPlaces: false,
        setup: false,
        // Demo products for Catalog section
        products: [
          {
            id: '1',
            title: 'Apple iPhone 8',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi tempora similique reiciendis, error nesciunt vero, blanditiis pariatur dolor, minima sed sapiente rerum, dolorem corrupti hic modi praesentium unde saepe perspiciatis.'
          },
          {
            id: '2',
            title: 'Apple iPhone 8 Plus',
            description: 'Velit odit autem modi saepe ratione totam minus, aperiam, labore quia provident temporibus quasi est ut aliquid blanditiis beatae suscipit odio vel! Nostrum porro sunt sint eveniet maiores, dolorem itaque!'
          },
          {
            id: '3',
            title: 'Apple iPhone X',
            description: 'Expedita sequi perferendis quod illum pariatur aliquam, alias laboriosam! Vero blanditiis placeat, mollitia necessitatibus reprehenderit. Labore dolores amet quos, accusamus earum asperiores officiis assumenda optio architecto quia neque, quae eum.'
          },
        ]
      };
    },

    // App routes
    routes: routes,

    // Register service worker
    serviceWorker: Device.cordova ? {} : {
      path: '/service-worker.js',
    },
    // Input settings
    input: {
      scrollIntoViewOnFocus: Device.cordova && !Device.electron,
      scrollIntoViewCentered: Device.cordova && !Device.electron,
    },
    // Cordova Statusbar settings
    statusbar: {
      iosOverlaysWebView: true,
      androidOverlaysWebView: false,
    },
  };


  

  function alertLoginData() {
    f7.dialog.alert('Username: ' + username + '<br>Password: ' + password, () => {
      f7.loginScreen.close();
    });
  }

  function InitializePanel(){
    console.log("panel eee",$user)
    var panel = f7.panel.create({
      el: '.panel-right',
      on: {
        opened: function () {
          console.log('Panel opened')
        }
      }
    })
  }
 
  function loggedIn(e){
    console.log("logged in ",e.detail.uid)
     InitializePanel();
     let userRef=$firestoreInstance.collection(`users`);
     userRef.doc(e.detail.uid).get()
     .then((qs)=>{
       if (qs.exists){
         let ui=qs.data();
         ui.id=e.detail.uid;
         userInfo.set(ui);

       

       }
     })
  }


  onMount(() => {

    f7ready(() => {
      const self=this;
      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        cordovaApp.init(f7);
      }
      // Call F7 APIs here
      // Request location permission
     // if (!$gotPosition)
     
      
      //initializeI18n();
    //Delay a few seconds then hide over
     

      //f7.data.gotPlaces=$locInitialized;


    });
  })
</script>