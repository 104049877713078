import { writable} from 'svelte/store';
import { writable as pwritable } from 'svelte-persistent-store/dist/local';
import { expwritable } from './expstore.js';

export const Drinks=writable([]);
export const curCat=writable('');

export const place=writable({ data: {name:'',pic:'',unknown:true} });

export const places=writable(null);
export const locInitialized=writable(false);

export const hideMainToolbar=writable(false);

export const loadingData=writable(false);
export const appInitialized=writable(false);


export const latestCocktails=expwritable('lcocktails',null,new Date(new Date().getTime() + (8 * 60) *60000));
export const popularCocktails=expwritable('pcocktails',null,new Date(new Date().getTime() + (8 * 60) *60000));
export const featuredCocktails=expwritable('JD_Featured',null,new Date(new Date().getTime() + (8 * 60) *60000));

export const lastScannedVenue=expwritable('scannedVenue',null,new Date(new Date().getTime() + (8 * 60) *60000));
export const qrScanConfirmed=expwritable('JustDrinks_scanConfirmed',false,new Date(new Date().getTime() + (8 * 60) *60000));
export const lastScannedCode=writable(null);
export const venueOrder=expwritable('JD_orderInfo',null,new Date(new Date().getTime() + (8 * 60) *60000));
export const venueAlcoholList=expwritable('JD_alcoholList',null,new Date(new Date().getTime() + (1 * 60) *60000));

export const comingSoon=writable(true);


export const appColor=pwritable('appColor','blue');
export const appTheme=pwritable('appTheme','light');
export const oldUser=pwritable('oldUser',false);
export const userInfo=pwritable('JD_UserPref',null);
export const promptNavMessage=expwritable('JD_NavPrompt',false,new Date(new Date().getTime() + (8 * 60) *60000));