
<script>
import { onMount,onDestroy, createEventDispatcher} from 'svelte';
import {firebaseMessagingLoaded,messaging} from './store'
import {firebaseLoaded} from '../firebase/store'

export let messageKey;
const dispatch = createEventDispatcher();
$: if ($firebaseLoaded){
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://www.gstatic.com/firebasejs/7.18.0/firebase-messaging.js";
  script.onload=onFirebaseMessagingLoaded;
  document.body.appendChild(script);
}
onMount(() => {
    console.log('--- notification component mounted --')
     
});

function onFirebaseMessagingLoaded(){
    console.log("-- Firebase Messaging loaded")
    firebaseMessagingLoaded.set(true);
    let fbmsg=firebase.messaging()
    if (messageKey)
        fbmsg.usePublicVapidKey(messageKey);

    messaging.set(fbmsg);
    
}

</script>