

<Popup bind:this={drinkPopup} class="drink-detail-popup drink-detail-standalone" on:popupOpened={initPopup} on:popupClosed={endPopup}>
    <div class="drink-popup-viewer">
      <View>
       <div class="page drink-popup-viewer-page no-toolbar" data-name="drink-popup-viewer-page">
     {#if drink}
     <Navbar transparent>
      <NavLeft>
       <a href="#1" class="link  sheet-close popup-close">
          <i class="icon icon-back"></i>
        </a>
      </NavLeft>
     </Navbar>
     <div class="drink-heading"><h1>{drink.name}</h1>
     {#if drink.descr}
        <p><em>{drink.descr}</em></p>
      {/if}
     </div>
     <div class="zoom-container">
        <div class="zoom-img">
          {#if drink.internal}
            <img src={"https://img.imageboss.me/justdrinks/height/500/" + drink.pic} alt="1" />
          {:else}
           <img src={"https://img.imageboss.me/cocktails/height/500/" + drink.pic} alt="1" />
          {/if}
        </div>
     </div>
     <div style="position: absolute; width:100%; height: 50px; bottom: 70px">
      <Block>
        <Button color="green" fill large sheetOpen=".sheet-action">Order drink</Button>
        <Button color="blue" popupClose>Back to menu</Button>
      </Block>
     </div>
      <Sheet class="sheet-confirm" position="top" closeByOutsideClick={true} style="height:300px;">
        <Page withNavbar>
          <Navbar title="Confirm Order"><NavRight><Link sheetClose>Close</Link></NavRight></Navbar>
            <Block style="margin-top:50px">
              <Row>
                <Col width="33"><img src="https://img.imageboss.me/justdrinks/width/125/man-bar.png" alt="" width="125"/></Col>
                <Col width="66"><p>Hey {$user.displayName}! You want a {drink.name} made with {curSelAlcohol} {curAlcoholLabel} 
                  {#if drink.customizeMixer}
                  and {selectedMixer}
                  {/if}
                served {selectedPrepName.toLowerCase()}. No Salt. Is this correct?</Col>
              </Row>
              <Button small color="green" fill sheetClose on:click={completeOrder}>Confirm</Button>
               <small><em>Note: Drinks may vary from photo. Ingredients may vary depending on location. Non-well alcohol may have a higher price. Please check with your server for more details.</em></small>
            </Block>
        </Page>
      </Sheet>
      <Sheet class="sheet-action" push on:sheetClose={(e)=>{ f7.$('.sheet-action').removeClass('expand'); expanded=false;}} closeByOutsideClick={true}>
          <View name="sheet-modal-view" class="brand-select-view">
           <div class="page" data-name="drink-sheet-view">
              <!--
                <div class="toolbar toolbar-bottom">
                  <div class="toolbar-inner">
                    <div class="left"></div>
                    <div class="right">
                      <Link sheetClose iconOnly iconIos="f7:chevron_compact_down"  iconAurora="f7:chevron_compact_down" iconMd="material:expand_more"></Link>
                    </div>
                  </div>
               </div>-->
              <div class="sheet-modal-swipe-step">
                    <Block style="margin-top:5px">
                    <small>Ingredients</small>
                    {#if drink.ingred}
                      <Swiper class="swiper-ingred" init={false}>
                        {#each drink.ingred as ig,index}
                        <SwiperSlide>
                              <IFeature w="50px" h="50px" title={ig} id={index} pic={'https://img.imageboss.me/ing/width/50/' + ig + '-Small.png'} lazy on:featureClicked={(e)=> clickIngredient(e.detail.id)}></IFeature>
                        </SwiperSlide>
                        {/each}
                      </Swiper>
                      {#if expanded}
                      <List style>
                      <ListItem title={curAlcoholLabel} smartSelect smartSelectParams={{searchbar: true, closeOnSelect: true,searchbarPlaceholder: 'Search ' + curAlcoholLabel}}>
                        <select name={curAlcoholId} bind:value={selectedAlc}>
                          <option value="well">Well</option>
                          {#each curAlcohol as a,index}
                            <option value={index}>{a.name}</option>
                          {/each}
                        </select>
                      </ListItem>
                      {#if drink.customizeMixer}
                          <ListItem title="Mixer" class="customizeMixer"  smartSelect   smartSelectParams={{closeOnSelect: true,on: { closed: closedCustomizeMixer }}}> 
                            <select name="mixer" id="selMixer"  bind:value={selectedMixer}>
                              {#each popMixers as p,index}
                                <option value={p}>{p}</option>
                              {/each}
                            </select>
                          </ListItem>
                      {/if}
                      <ListItem title="Prepared"  smartSelect smartSelectParams={{closeOnSelect: true}}> 
                        <select name="prepare" id="selPrepared"  bind:value={selectedPrep} >
                           {#each prepareMethods as p,index}
                            <option value={index}>{p}</option>
                          {/each}
                        </select>
                      </ListItem>
                      {#if hasSalt}
                        <ListItem title="Salt">
                          <div slot="after">
                          <Stepper fill value="1" max="1"></Stepper>
                          </div>
                        </ListItem>
                       {/if}
                      </List>
                      {/if}
                    {/if}
                    <Button fill large color="default" on:click={orderDrink(drink.id)}>Order Drink</Button>
                    </Block>
                  </div>
            </div>
            </View>
                    </Sheet>
      {/if}
     
     </div>
      </View>
    </div>
    
</Popup>
<style>
:global(.sheet-action .list ul) {
  margin-top:30px;
}
.page {
  background:#000 !important;
}
h1 { color: #fff; margin:0; font-size: 2.1em }
.drink-popup-viewer {
  position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 400;
}
.drink-heading {
    position:absolute;
    top: 35px;
    left:16px;
    z-index:410;
    padding: 5px;
}
.drink-heading p {
  margin: 0px;
  color: gray;
 color: #fff;
   text-shadow: 2px 2px 4px #000000;
}
.drink-descr {
  position: relative;
  margin:10px;
  padding: 5px;
  text-shadow: 2px 2px 4px #000000;
}
:global(.brand-select-view .smart-select-page .list) {
  margin-top: 102px;
}
.drink-popup-viewer-page {
    background: none;
}
.zoom-container {
    width: 100%;
    height: 100%;
  
}
.zoom-container img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  

}

/*
.zoom-container::after {
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 100%);
  margin-top: -56px;
  height: 56px;
  width: 100%;
  content: '';
}
:global(.theme-dark .zoom-container::after) {
  background: linear-gradient( rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%) !important;
}
*/

.zoom-img::before {
  position: absolute;
  background: linear-gradient( rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%) !important;

 /* background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0, #fff 100%); */
  /*background: linear-gradient( rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%) !important;*/
  top:0px;
  height: 80px;
  width: 100%;
  content: '';
}
:global(.theme-dark .zoom-container::before) {
  background: linear-gradient( rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%) !important;
}

:global(.drink-detail-standalone.modal-in) {
    transition-duration: 0ms;
    animation: photo-browser-in 400ms;
}


:global(.sheet-action.expand) {
  transition: height 500ms;
  height:450px
}

</style>
<script>
export let drink=null;
export let popupOpened=false;
export let name;
export let zoom=false;

let expandAction=false;
let expanded=false;
let bindCustomizeMixer;

import { tick } from 'svelte'
import { f7,f7ready, View,Page,Link, Popup,NavRight,NavTitle,NavLeft,List,Stepper,ListItem,Sheet,Button,Swiper,Toolbar,PageContent,CardFooter,Row,Col,SwiperSlide,Navbar, BlockTitle, Block,Card,CardHeader,CardContent,SkeletonBlock,Chip } from 'framework7-svelte';
import {place,venueAlcoholList,userInfo} from '../js/store'
 import IFeature from './dts-shared-components/img-feature.svelte'
import HeaderBack from './dts-shared-components/ui/header-back-img.svelte'
import {user,openPopup} from './dts-shared-components/auth/store'
let curAlcohol;
let curAlcoholLabel;
let curAlcoholId;
let curSelAlcohol='';
let selectedAlc="well";
let selectedPrep="0";
let selectedMixer="";
let selectedPrepName="";
let ingredCustomize=[];
let selMethodBox;
let prepareMethods=['Standard','Shaken','Chilled','Blended','On the rocks','Neat']
let hasSalt=false;
let popMixers=['Club Soda','Tonic water','Cranberry juice','Orange juice','Pineapple juice','Coca-Cola','Diet coke','Ginger ale','Ginger beer','Lemon-lime soda']

let swiperInstance;
let drinkPopup=null;

function initializeSwiper(){
  tick().then(()=>{
 swiperInstance=f7.swiper.create('.swiper-ingred', {
    speed: 500,slidesPerView: '4',spaceBetween: 8,slideToClickedSlide:true
  });
  })
}
let sheet;

function initPopup(){
  selectedAlc="well"
  if (drink.customizeMixer){
      if (drink.defaultMixer)
        selectedMixer=drink.defaultMixer;


  } 


  // Check if has salt
   let fi=drink.ingred.findIndex((u)=>{
      return u == "Salt"
   });
   if (fi > -1) hasSalt=true;

  

  initializeSwiper();
  sheet=f7.sheet.open(".sheet-action")
}
function closedCustomizeMixer(){
  // Update mixer ingred pic
  drink.ingred[1]=selectedMixer
}
function endPopup(){
  f7.$('.sheet-action').removeClass('expand')
  f7.swiper.destroy('.swiper-ingred')
  f7.sheet.close(".sheet-action")

}
function findFirstAlcohol(){
  let found=-1;
  let ingreds=drink.ingred;
  let arr=["tequila","vodka","rum","whisky","gin","scotch","bourbon"];

    return arr.findIndex(function (v) {
        return ingreds.indexOf(v);
    });

}
function clickIngredient(){
  console.log(drink)
  let venueAlc=$venueAlcoholList;
  let alcindex=findFirstAlcohol();
  if (alcindex > -1){
      let ingred=drink.ingred[alcindex];
      if (ingred){
        let idl=ingred.toLowerCase();
        if (venueAlc[idl]){
          curAlcohol=venueAlc[idl].list;
          curAlcoholId=idl;
          curAlcoholLabel=ingred;
        }
      }
  }else{
    curAlcohol=[];

  }
  
  //swiperInstance.slideTo(swiperInstance.clickedIndex,500)
  //swiperInstance.activeIndex=swiperInstance.clickedIndex;
  console.log(swiperInstance.clickedIndex);
  f7.$('.sheet-action').addClass('expand')
  expanded=true;
}
function completeOrder(){
     f7.dialog.preloader();
      window.setTimeout(()=>{
            f7.dialog.close();
            f7.dialog.alert('Request sent! Your drink should be delivered shortly. Please be patient.');
            f7.popup.close();
            console.log($userInfo)
            /*
            window.setTimeout(()=>{
                  const res=	window.fetch(`https://us-central1-fiesta-drinks.cloudfunctions.net/sendNotif`,
                  {method:"POST",headers:{"Content-Type":"application/json"},
                  body:JSON.stringify({token: $messagingToken,title: "We've received your order!",msg:`We are preparing your ${drink.name}!`,pic: drink.pic,icon:`${drink.pic}/preview`, ext:true,id:drink.id})});
                  res.then((s)=>{
                  })
            },10000)
            */
      },2000)
}
function orderDrink(id){
    /*
     if ($user.isAnonymous){
       f7.dialog.confirm("We need to complete your account setup before ordering (No credit card required)","Setup required",
       function () {
          // OK.
           f7.popup.close();
          openPopup.set(true);

       },function () {

       });
       return;
     }
    */

     if (selectedAlc == "well")
      curSelAlcohol="well"
     else{
       curSelAlcohol=curAlcohol[selectedAlc].name;
     }

     selectedPrepName=prepareMethods[selectedPrep];


     f7.sheet.open('.sheet-confirm');
     expanded=false;
     f7.$('.sheet-action').removeClass('expand')
   
     //f7.dialog.confirm(`Request (1) ${drink.name} from ${$place.data.name}?`, function () {
       /*
      f7.dialog.preloader();
      window.setTimeout(()=>{
            f7.dialog.close();
            f7.dialog.alert('Request sent! Your drink should be delivered shortly. Please be patient.');
            f7.popup.close();
            window.setTimeout(()=>{
                  const res=	window.fetch(`https://us-central1-fiesta-drinks.cloudfunctions.net/sendNotif`,
                  {method:"POST",headers:{"Content-Type":"application/json"},
                  body:JSON.stringify({token: $messagingToken,title: "We've received your order!",msg:`We are preparing your ${drink.name}!`,pic: drink.pic,icon:`${drink.pic}/preview`, ext:true,id:drink.id})});
                  res.then((s)=>{
                  })
            },10000)
            
      },2000)

      });
      */

  

    /*
     app.dialog.create({
    title: drink.name,
    text: 'D',
    buttons: [
      {
        text: 'Button 1',
      },
      {
        text: 'Button 2',
      },
      {
        text: 'Button 3',
      },
    ],
    verticalButtons: true,
  }).open();
  */
}


</script>