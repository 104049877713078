<Page onPageInit={onPageInit} name="profile">
  <Navbar title="My Profile" backLink="back">
    <NavRight><Link href="#1s" on:click={save}>Save</Link></NavRight>
  </Navbar>
  <Block strong class="text-align-center">
    <form name="form-img-upload"  method="POST" action="#" enctype="multipart/form-data">
    <figure>
        <input type="file" id="imgupload" style="display:none" on:change={onImgChange}/> 
        <img src={pic + '?cache=1234'} alt={name} width="128" on:click={(e) => f7.$('#imgupload').click()} />
        <figcaption>{name}</figcaption>
    </figure>
    </form>
  </Block>
  <List form class="form-profile">
      <ListInput
      value={name}
      onInput={e => name=e.target.value}
            label="Name"
            type="text"
            placeholder="Your name"
            info="In case we need it for your order."
            required
            validate
            clearButton
            validateOnBlur
   >
    </ListInput>

  </List>
  <BlockTitle>Favorite Alcohol</BlockTitle>
  <Block class="text-align-center">
  
  {#each alcohol as a}
    <label class="chip-selectable">
        <input type="checkbox" name="alcohol[]" bind:group={alcoholList} value={a} />
        <div class="chip chip-outline color-gray">
            <div class="chip-label">{a}</div>
        </div>
    </label>
  {/each}
 </Block>
</Page>
<script>
import {onMount} from 'svelte'
 import {user} from '../components/dts-shared-components/auth/store'
 import {userInfo} from '../js/store'
 import {firestoreInstance} from '../components/dts-shared-components/firebase/store'
 
  import { f7,Page, Navbar, Block, BlockTitle,List,ListItem,ListInput,NavRight,Link } from 'framework7-svelte';
   let alcohol = [
    "Tequila","Vodka","Rum","Gin","Whiskey","Bourbon","Scotch","Red Wine","White Wine","Lite Beer","Dark Beer","Amber/IPA Beer"];
  let alcoholList=[];
  let name;
  let pic;
$: if ($user){
    /* Whenever profile changes, update screen here */
    pic=$user.photoURL;
    name=$user.displayName;

}

function save(){
    if (f7.input.validateInputs('.form-profile')){
        console.log("name = ",name)
            $user.updateProfile({
                    displayName: name,
                    }).then(function(r) {
                          let ui=$userInfo;
                          ui.alc=alcoholList;
                          let userRef=$firestoreInstance.collection(`users`);
                           userRef.doc($user.uid)
                           .set(ui,{merge:true})
                           .then(()=>{
                                 userInfo.set(ui);
                                 let uo=firebase.auth().currentUser;
                                 user.set(uo)
                                 //console.log(uo)

                                 //uo.reload().then(() => {
                                 //   const refreshUser = firebase.auth().currentUser;
                                 //   console.log(refreshUser)
                                    // do your stuff here
                                 // })
                                 f7.views.current.router.back()
                           })

                    });
    }

}

function loadData(){
    alcoholList=$userInfo.alc;
    name=$user.displayName;
    pic=$user.photoURL;
}

function changeImage(input){
 if (input.files && input.files[0]) {
    let formData = new FormData();
    let fn=$user.uid.toLowerCase();


    formData.append('filename', fn);
    formData.append('file', input.files[0]);

     const options = {
         method: 'POST',
        body: formData,
        // headers: {'Content-Type': 'multipart/form-data'}
     }
     let f=input.files[0];
     let ext=f.type == "image/png" ? "png" : "jpg";

    console.log(input.files[0])
    fetch(`https://us-central1-fiesta-drinks.cloudfunctions.net/uploadUserImage`, options)
    .then((response) => {
          $user.updateProfile({
                    displayName: name,
                    photoURL: `https://img.imageboss.me/justdrinks/width/128/users/${fn}.${ext}`
                    }).then(function(r) {
                        console.log("updated profile",r)
                        user.set($user)

                    });
    
    })
    .catch((e)=>{

    })

  }
}
function onImgChange(e){
    console.log("got it!",e)
    changeImage(this);

}

function onPageInit(){
    loadData();
}
</script>