<Page name="locCheck" noToolbar>
<Navbar title="Drink Up!"></Navbar>
<div class="loc-screen">
<Swiper class="content swiper-loc">
  <SwiperSlide>
     <Block style="text-align:center">
            <Icon material="near_me" color="default" size="128" class="elevation-4" />
            <p>Drink Up uses location information to help you find, and order drinks.</p>
            <Row>
              <Col><Button outline href="/home/">Skip</Button></Col>
              <Col><Button fill on:click={allowLoc}>Allow</Button></Col>
            </Row>
     </Block>
  </SwiperSlide>
  <SwiperSlide>
     <Block style="text-align:center">
            <Icon material="notifications" color="default" size="128" class="elevation-4" />
            <p>Drink Up uses notifications to send you drink order status.<br/>
              <em>(You can change these settings later)</em>
            </p>
            <Row>
              <Col><Button outline href="/home/">Skip</Button></Col>
              <Col><Button fill on:click={allowNotif}>Allow</Button></Col>
            </Row>
     </Block>
  </SwiperSlide>
</Swiper>
</div>
</Page>
<script>
  import { onMount } from 'svelte';
  import { f7,f7ready,Page, Navbar, Block,Button,Icon,Toolbar,Link,Swiper,SwiperSlide,Row,Col } from 'framework7-svelte';
  import {updatePosition} from '../components/dts-shared-components/location/store'
  import {messaging,gotNavPermission,messagingToken} from '../components/dts-shared-components/notification/store'
  import {gotPosition} from '../components/dts-shared-components/location/persiststore'
import {locInitialized,hideMainToolbar} from '../js/store'
let swiperInstance;
let locPermission=false;

function skipNext(){
  swiperInstance.slideNext(700)
}
function allowLoc(){
    //updatePosition.set(true);
    updatePosition.set(true);
    skipNext();
 
}
function allowNotif(){
  gotNavPermission.set(true);
  f7.$(".main-toolbar").show();
  f7.views.main.router.navigate({ name: 'home' });
}
onMount(() => {
    f7ready(() => {
        swiperInstance=f7.swiper.get('.swiper-loc')
        //f7.$('#cover').hide();
        //hideMainToolbar.set(true);
       //f7.$(".main-toolbar").hide();
    });
  })
</script> 
