<Page name="alerts" onPageInit={PageInit} ptr ptr-distance={55} onPtrRefresh={refreshNotifs}>
  <Navbar title="Order Notifications" />

{#if $venueOrder}
 <BlockTitle medium>{$venueOrder.ownerName}'s table</BlockTitle>
 <Block>
  You have {$venueOrder.users.length} people at your table.

 <Swiper params={{slidesPerView: 4,speed: 200,width:'375'}}>
    {#each $venueOrder.users as u}
        <SwiperSlide><img src={u.pic} style="border-radius:50%" width="50px" alt="1"/></SwiperSlide>
    {/each}
 </Swiper>
 </Block>
 <List mediaList>
    <ListItem divider>Drink Requests</ListItem>
    <ListItem title="Sally" subtitle="Requested a Margarita" after="5 min ago">
        <div slot="media"><img src="https://img.imageboss.me/cocktails/width/100/5noda61589575158.jpg/preview" width="50px" alt="" /></div>
        <div slot="text"><Icon material="thumb_up_alt" color="green"></Icon>&nbsp; Drink accepted and will be delivered soon.</div>
    </ListItem> 
    <ListItem title="Mike" subtitle="Requested a Blue Moon" after="10 min ago">
        <div slot="media"><img src="https://img.imageboss.me/justdrinks/width/100/beers/beer-ipa-draft.jpg" width="50px" alt="1"/></div>
        <div slot="text"><Icon material="thumb_down_alt" color="red" />&nbsp; Unable to make this drink. Please choose another.</div>
    </ListItem> 
 </List>
 {/if}
   <Popover class="notif-popover" target=".notif-link">
    <center>
    {#if curNotifications.length > 0}
    <p>{curNotifications[0].title}</p>
    {/if}
    </center>
    </Popover>
    <Popup class="notif-shownotif" >
    <Page>
        <Navbar title="Notifications">
            <NavRight>
            <Link popupClose>DONE</Link>
            </NavRight>
        </Navbar>
        {#if curNotifications.length}
        <List mediaList>
            {#each curNotifications as n}
            <ListItem title={n.title} swipeout>
                <img src={n.pic} width="50px" slot="media" alt="" />
                <div slot="text">{n.msg}</div>
                <div slot="subtitle">{formatDistanceToNow(n.time)} ago</div>

            </ListItem>
            {/each}
        </List>
        {:else}
            <center>
                <Block>
                    You have no new notifications.
                </Block>
            </center>
        {/if}
        </Page>
</Popup>
   
</Page>
 
 
<script>
 import { f7,f7ready, Page,Link,List,Popover,ListItem, Icon,Popup,NavRight,CardFooter,Swiper,SwiperSlide,PhotoBrowser,Navbar, BlockTitle, Block,Card,CardHeader,CardContent,SkeletonBlock,Chip } from 'framework7-svelte';
import {formatDistanceToNow,add} from 'date-fns'
let curNotifications=[];
import {userInfo,promptNavMessage,venueOrder} from '../js/store'
import {onMount} from 'svelte'
let notifEnabled=false;
import {notifications, messaging,messagingToken} from '../components/dts-shared-components/notification/store'
import {firestoreInstance} from '../components/dts-shared-components/firebase/store'
import {user} from '../components/dts-shared-components/auth/store'
onMount(()=>{
})
function refreshNotifs(done){
    console.log("refresh")
    	setTimeout(function() {
					f7.toast.show({
						text: 'No new notifications',
						position: 'bottom',closeButton:true,closeTimeout:2000,
						cssClass: 'toast-round bg-color-blue text-color-white'
					});
					done();
				}, 3000);
}
function GetUserNoticationStatus(){
    if ($userInfo.notifEnabled)
        notifEnabled=$userInfo.notifEnabled
    if (!notifEnabled){
        if (!$promptNavMessage){
            promptNavMessage.set(true)
            f7.dialog.confirm("We would like to send you notifications on drink orders. Would you like to enable notifications now?", promptNotifOK, promptNotifCancel)
        }
    }
}

function saveNotifStatus(token){
    console.log($user.uid)
 let userRef=$firestoreInstance.collection(`users`);
     userRef.doc($user.uid)
     .set({notifEnabled: true,messageToken: token},{merge:true});
}
function promptNotifOK(){
     $messaging.requestPermission()
     .then(()=>{
          return $messaging.getToken();
      })
      .then((token)=>{
          saveNotifStatus(token)
           messagingToken.set(token)
        
      })
      .catch((e)=>{
          f7.dialog.alert(e);
      })
}
function promptNotifCancel(){

}

//$: if ($userInfo) GetUserNoticationStatus();

function PageInit(){
}

</script>