<script>
import { onMount } from 'svelte';
import {firebaseLoaded,fbMessagingToken,firebaseAuthLoaded,firebaseConfig,firestoreInstance,GeoFirestoreLoaded,firebaseMessagingLoaded,messaging,messagingPermission,messagingToken} from './store'
export let config;
//export let geoFirestore=false;
export let messageKey=null;
import {notifications} from '../notification/store.js'
export let loadScripts=false;
let token;

onMount(() => {
    console.log('--- firebase component mounted --')
        if (loadScripts){
            if (typeof firebase !== 'object'){
            console.log("no firebase")
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://www.gstatic.com/firebasejs/7.18.0/firebase-app.js";
            script.onload=onFirebaseLoaded;
            document.body.appendChild(script);
            }else{
                onFirebaseLoaded();
            }
            if (typeof firebase === 'object' && typeof firebase.firestore === 'object'){
                onFireStoreLoaded();
            }else{
                let script = document.createElement("script");
                script.type = "text/javascript";
                script.src = "https://www.gstatic.com/firebasejs/7.18.0/firebase-firestore.js";
                script.onload=onFireStoreLoaded;
                document.body.appendChild(script);
            }
            if (typeof firebase === 'object' && typeof firebase.auth === 'object'){
                onFirebaseAuthLoaded();
            }else{
                    let script = document.createElement("script");
                    script.type = "text/javascript";
                    script.src = "https://www.gstatic.com/firebasejs/7.18.0/firebase-auth.js";
                    script.onload=onFirebaseAuthLoaded;
                    document.body.appendChild(script);
            }
    }else{
        onFirebaseLoaded();
        onFireStoreLoaded();
        onFirebaseAuthLoaded();
    }
   
});

function onFirebaseLoaded(){
    console.log("-- Firebase loaded")
    firebaseLoaded.set(true);
    firebase.initializeApp(config)
}
function onFirebaseAuthLoaded(){
    console.log("-- Firestore auth loaded")
    firebaseAuthLoaded.set(true);
}
function onFireStoreLoaded(){
    console.log("-- Firestore loaded")
    let fs=new firebase.firestore();
    //console.log("*** firestore loaded",fs);
    

    firestoreInstance.set(fs);

}


/*
$: if ($firebaseMessagingLoaded){
        
        $messaging.requestPermission()
        .then(()=>{
            messagingPermission.set(true);
            return $messaging.getToken();
        })
        .then((token)=>{
            messagingToken.set(token)
            
            // Send token to server.
          

            
        })
}
*/

</script>