<Page name="venue" on:pageInit={onPageInit} pageContent={false}>
 {#if venue}
  <Navbar title={venue.name} backLink="Back" subtitle="Full bar, specialty drinks" sliding>
    
      <!---
    <Subnavbar class="subnav-venue">
      {#if venue.server && $venueOrder}
        <div class="venue-server">
          
              <div class="display-flex align-items-center justify-content-space-between">
                <div class="user-small display-inline-flex align-items-center">
                  <img src={$user.photoURL} alt="Server" width="40px" />
                  <div class="user-infos">
                    <div class="user-name">{$venueOrder.ownerName + ' table'}</div>
                    <div class="user-info"><b>{$venueOrder.users.length}</b> person(s) checked in at table #{$lastScannedVenue.tableNo}</div>
                  </div>
                </div>
                <a class="rate-badge bg1 display-inline-flex align-items-center link popup-open" href="#1" data-popup=".popup-rate">
                  <img src="images/star2.png" alt="rate">
                  <span>{venue.server.rating}</span>
                </a>
              </div
        </div>
      {/if}
    </Subnavbar>  
    >-->
 
  </Navbar>
  <Toolbar tabbar position='top' scrollable>
   <Link tabLink="#t1" tabLinkActive text="Cocktails">
       </Link>
         <Link tabLink="#t3" text="Wine">
       </Link>
       {#each dynTabData as td}
        <Link tabLink={`#${td.id}`} text={td.name}>
       </Link>
       {/each}
       
      <Link tabLink="#t12" text="Non-Alcoholic">
       </Link>
  </Toolbar>
  
  
  <!--
  <div style="position: relative; margin-top:40px" class="page-content venue-page-content">
      <BlockTitle>Browse categories</BlockTitle>
      <Block style="margin-bottom:0;">
        <Swiper class="swiper-category" params={{slidesPerView: "3.65", spaceBetween: 5}}>
          <SwiperSlide>
            <IconCatButton src="https://img.imageboss.me/justdrinks/height/30/cocktail-icon.png" round figure={true} heading="Cocktails" link="#1" tabLink={true} active={true} bgColor="#d2f8d2" width="auto" height="30px"  />
          </SwiperSlide>
          <SwiperSlide>
            <IconCatButton link="#2" tabLink={true} round figure={true} heading="Beer" active={false} src='https://img.imageboss.me/justdrinks/height/30/beer-icon2.png' bgColor="#fcead4" width="auto" height="30px" />
          </SwiperSlide>
            <SwiperSlide>
            <IconCatButton link="#3" tabLink={true} round figure={true} heading="Wine" active={false} src="https://img.imageboss.me/justdrinks/height/30/wine-icon.png" bgColor="#ffd9e8" width="auto" height="30px" />
          </SwiperSlide>
            <SwiperSlide>
            <IconCatButton link="#4" tabLink={true} round figure={true} heading="Shots" src='https://firebasestorage.googleapis.com/v0/b/fiesta-drinks.appspot.com/o/shot-icon.png?alt=media&token=98875f20-902d-47fc-b550-f523458c161c' bgColor="#eaedfc" width="auto" height="30px" />
          </SwiperSlide>
        </Swiper>
      </Block>
   -->   
      <Tabs animated>
        <div id="t1" class="page-content tab tab-active" style="padding-bottom:200px;margin-top:108px;">
        
          <BlockTitle medium>Cocktails</BlockTitle>
                        <BlockTitle>Featured Cocktails</BlockTitle>
                        {#if venue.cocktails.featured}
                                <Swiper class="swiper-featured" pagination params={{speed: 300,slidesPerView: "2.3",effect:'coverflow',cubeEffect: {  slideShadows: true},pagination:{dynamicBullets: true}}}>
                                {#each venue.cocktails.featured as fi,index}
                                    <SwiperSlide>
                                    <CardImg id={index} cornerRadius="8px" height="150px" content={false} bold topHeadingTitle={fi.name} fadeOut imgUrl={'https://img.imageboss.me/cocktails/height/150/' + fi.pic} on:cardClicked={(e)=> clickDrink('popular',e.detail.id)}>
                                    </CardImg>
                                    </SwiperSlide>
                                  {/each}
                                </Swiper>
                        {:else}
                            <Swiper class="skeleton-text skeleton-effect-fade" params={{slidesPerView:"2.3",effect:'coverflow'}}>
                                <SwiperSlide><SkeletonBlock style="height: 150px"></SkeletonBlock></SwiperSlide>
                                <SwiperSlide><SkeletonBlock style="height: 150px"></SkeletonBlock></SwiperSlide>
                                <SwiperSlide><SkeletonBlock style="height: 150px"></SkeletonBlock></SwiperSlide>
                            </Swiper>
                        {/if}
                        <BlockTitle>Popular</BlockTitle>
                        {#if venue.cocktails.popular}
                            <center>
                            <Swiper params={{slidesPerView: '3.8',spaceBetween: 2,speed: 700,centeredSlides:true,centeredSlidesBounds:true,pagination:{dynamicBullets: true}}}>
                            {#each venue.cocktails.popular as l,index}
                              <SwiperSlide>
                                <IFeature w="75px" h="75px" title={l.name} id={index} pic={'https://img.imageboss.me/cocktails/width/75/' + l.pictn} lazy on:featureClicked={(e)=> clickDrink('popular',e.detail.id)}></IFeature>
                              </SwiperSlide>
                            {/each}
                            </Swiper>
                            </center>
                        {/if}

                        {#if venue.cocktails.latest}
                              <BlockTitle>Latest Cocktails</BlockTitle>
                                <center>
                                <Swiper params={{slidesPerView: '3.8',spaceBetween: 2}}>
                                {#each venue.cocktails.latest as l,index}
                                  <SwiperSlide>
                                    <IFeature w="75px" h="75px" title={l.name} id={index} pic={'https://img.imageboss.me/cocktails/width/75/' + l.pictn} lazy on:featureClicked={(e)=> clickDrink('latest',e.detail.id)}></IFeature>
                                  </SwiperSlide>
                                {/each}
                              </Swiper>
                              </center>
                        {/if}
                  </div>
                  <Tab id="t3" class="page-content" on:tabShow={GetVenueWine} >
                    <BlockTitle medium>Wine</BlockTitle>
                    <DrinkCards loading={loadingWine} drinks={venue.wine} on:openDetails={(e)=> clickDrink('wine',e.detail.index)}></DrinkCards>
                  </Tab>
                  {#each dynTabData as dt}
                    <Tab id={dt.id} class="page-content" on:tabShow={(e)=> GetDrinks(dt.type)}>
                        <BlockTitle medium>{dt.name}</BlockTitle>
                        {#if venue[dt.type]}
                            <center>
                                <Swiper params={{slidesPerView: 3}}>
                                {#each venue[dt.type] as l,index}
                                  <SwiperSlide>
                                    {#if l.internal}
                                      <IFeature w="75px" h="75px" title={l.name} id={index} pic={'https://img.imageboss.me/justdrinks/width/75/' + l.pic} on:featureClicked={(e)=> clickDrink(dt.type,e.detail.id)}></IFeature>
                                    {:else}
                                      <IFeature w="75px" h="75px" title={l.name} id={index} pic={'https://img.imageboss.me/cocktails/width/75/' + l.pic} on:featureClicked={(e)=> clickDrink(dt.type,e.detail.id)}></IFeature>
                                    {/if}
                                  </SwiperSlide>
                                {/each}
                              </Swiper>
                            </center>
                        {/if}
                        {#if venue.brands[dt.type]}
                          <BlockTitle>{dt.name} List</BlockTitle>
                          <Block style="text-align:center">
                          <Row>
                          {#each venue.brands[dt.type].list as b,index}
                            <Col width="50">{b.name}</Col>
                          {/each}
                          </Row>
                          </Block>
                        {/if}
                    </Tab>
                  {/each}
          </Tabs>
          <Block style="height: 50px">

          </Block>

   <PopupChat botName="Hostess Bot" userName={$user.displayName} lid={venue.id} suggest={suggest} action={botAction} botAvatar='https://firebasestorage.googleapis.com/v0/b/fiesta-drinks.appspot.com/o/hostess2.png?alt=media&token=df422999-57cb-4e36-846c-c6618559c36b'></PopupChat>

 {/if}
 
    <FabBackdrop/>
   <FabBot materialIcon="local_bar"  imgSrc="https://firebasestorage.googleapis.com/v0/b/fiesta-drinks.appspot.com/o/hostess-icon.png?alt=media&token=87b8b6ec-0878-45fc-add2-e61fda455ecf" show={showBot} color="#333" buttons={botButtons} on:buttonClick={botButtonClick}></FabBot>
  <DrinkPopup drink={selDrink}></DrinkPopup>
</Page>
<style>
:global(.swiper-featured) {
  margin-top:0px;
  padding-top:0px;
}
.page-content {
  padding-top:0px;
}
.rate-badge {
  line-height: 14px;
  font-size: 13px;
  font-weight: 600;
  color: #ff1973;
  flex-direction: column;
  padding: 0px 10px;
  border-radius: 5px;
  height: 40px;
}

.rate-badge {
  margin-right:10px;
}
.rate-badge img {
  width: 20px;
  height: 20px;
  display: block;
  max-width: 100%;
  
}


.venue-profile {
    width: 75%;
    min-height:120px;
    margin: 0 auto;
    position: relative;
    top: 142px;
    background: #fff;
    color: #000;
    border-radius: 10px;
   box-shadow: 0 12px 10px -10px gray;
   padding-left:10px;
   padding-top:4px;
}
.venue-server {
  width:100%;
    margin: 0 auto;
  margin-bottom: 3px !important;
}
:global(.theme-dark .subnav-venue){
  background: #202020;
 
}
:global(.theme-dark .venue-profile) {
  background: var(--f7-list-bg-color) !important;
  color:#fff !important;
   box-shadow: 0 12px 10px -10px #333 !important;
}
:global(.theme-dark .venue-server .user-infos .user-name) {
  color:#fff;
}
:global(.theme-dark .venue-server .user-infos .user-info) {
  color: #9ACD32;
}
:global(.theme-dark .venue-server .rate-badge) {
  background: #1e224c;
}
.info {
     font-size: 12px;
    color: #7d7d7d;
}
:global(.theme-dark .venue-server .info){
   color: #fff;
}
.venue-info {
   padding-left: 10px;
}
.venue-info h2, .venue-info h1 {
    margin:0;
}



.user-small {
    margin-top:8px;
}
.user-small img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: block;
    max-width: 100%;
}
.venue-content {
  margin-top:100px;
}
:global(.venue-content .block-title) {
    margin-bottom: 5px;
}

:global(.featured-pagination .swiper-pagination-bullet) {
  margin-left: 6px;
}
:global(.theme-dark .fab .fab-text) {
  color: #fff
}
</style>
<script>
 import { f7,f7ready,Tabs,Tab,PageContent,Row,Col,Subnavbar,Popover,Button,NavTitle,NavTitleLarge,FabBackdrop,NavLeft,Icon, Swiper,SwiperSlide,Page,Link,Toolbar,Popup,NavRight,CardFooter,List,ListItem,PhotoBrowser,Navbar, BlockTitle, Block,Card,CardHeader,CardContent,SkeletonBlock,Chip } from 'framework7-svelte';
import {user2} from '../assets/user2.jpg'
import {star} from '../assets/star2.png'
import {beerIcon} from '../assets/icons/beer-icon2.png'
import {cocktailIcon} from '../assets/icons/cocktail-icon.png'
import {wineIcon} from '../assets/icons/wine-icon.png'
import sol from '../assets/solyluna_small.jpg'
import football from '../assets/football-small.png'
import hostessIcon from '../assets/icons/hostess-icon.png'
import { onMount, onDestroy,tick } from 'svelte';
import beer from '../assets/beer.png'
import {searchDrinks} from '../js/core.js'
import DrinkPopup from '../components/popup-drink.svelte'
import NotificationBell from '../components/dts-shared-components/notification/bell.svelte'
import FabBot from '../components/dts-shared-components/chatbot/bot-fab.svelte'
import PopupChat from '../components/popup-chat.svelte'
import hostess from '../assets/girl-drink.png'
import {places,latestCocktails,popularCocktails,featuredCocktails,userInfo,lastScannedVenue,qrScanConfirmed,venueOrder,venueAlcoholList} from '../js/store'
import {user} from '../components/dts-shared-components/auth/store'
import CardImg from '../components/dts-shared-components/card-img-back.svelte'
import IFeature from '../components/dts-shared-components/img-feature.svelte'
import HeaderBack from '../components/dts-shared-components/ui/header-back-img.svelte'
import IconCatButton from '../components/dts-shared-components/ui/icon-tab-button.svelte'
import DrinkGlass from '../components/drink-glass.svelte'
import {firestoreInstance} from '../components/dts-shared-components/firebase/store'
import DrinkCards from '../components/drinks-card.svelte'
  export let f7route;
  let tableInfo=null;

let suggest=null;


let venue=null;
let loadingWine=true;

/* Events */



let selDrink;
let showBot=false;
let botButtons=[{label: `"Surprise me!`,value:"Surprise me!",iconMaterial:"chat"},{label: `"Suggest a drink"`,value:"Suggest a drink", iconMaterial:"chat"},{label: `"Search a cocktail"`,value:"Search a cocktail", iconMaterial:"search"}]

let eDuration;
let popular=[1,2,3,4,5],latestC=[1,2,3,4,5];
let loadingitems=[1,2,3,4,5];
let loadingArr=[1,2,3,4,5];
let loadingPopular=true;
let loadingLatest=true;
let placeName;
let userName="";

let featured=[{
  name:'Strawberry Margarita',
  measure:[],
  ingred:[],
  pic:'https://www.thecocktaildb.com/images/media/drink/tqyrpw1439905311.jpg',
  id:'12322',
  isExt:true
},
{
  name:'Moscow Mule',
   measure:[],
  ingred:[],
  pic:'https://www.thecocktaildb.com/images/media/drink/3pylqc1504370988.jpg',
  id:'11009',
  isExt:true
},
{
  name:'Margarita',
   measure:[],
  ingred:[],
  pic:'https://www.thecocktaildb.com/images/media/drink/5noda61589575158.jpg',
  id:'11007',
  isExt:true
}
]


let dynTabData=[
   { id:'tb-beers',name:'Beers', type:'beer'},
  { id:'tb-tequila',name:'Tequila', type:'tequila'},
  { id:'tb-vodka',name:'Vodka', type:'vodka'},
  { id:'tb-gin',name:'Gin', type:'gin'},
   { id:'tb-rum',name:'Rum', type:'rum'},
  { id:'tb-bourb',name:'Bourbon', type:'bourbon'},
  { id:'tb-whiskey',name:'Whiskey', type:'whiskey'},
   { id:'tb-sco',name:'Scotch', type:'scotch'}
]

let hhEnd=add(new Date(),{hours: 2});
let botAction="welcome-silent";

import {formatDistanceToNow,add} from 'date-fns'
function clickFeatured(index){
  selDrink=featured[index];
  console.log(index,popular)
  f7.popup.open(".drink-detail-popup");
}
function clickDrink(type,index){
  console.log(type,index);
  if (type == 'popular')  selDrink=venue.cocktails.popular[index];
  else
  if (type == 'latest'){
    selDrink=venue.cocktails.latest[index];
  }else{
    console.log(venue[type]);
    selDrink=venue[type][index];
  }
  window.setTimeout(()=>{
    f7.popup.open(".drink-detail-popup");

  },50)
}

function botButtonClick(e){
  let bindex=e.detail.b;
  suggest=botButtons[bindex].value;
  f7.popup.open(".b-popup");

}

function setupActiveTableEvent(id){
  console.log("setup Active table event,")
  $firestoreInstance.collection(`activeTables/${id}/tables`).doc($lastScannedVenue.tableNo)
      .onSnapshot(function(doc) {
        console.log("1",id,$lastScannedVenue.tableNo)
        if (doc.exists){
          venueOrder.set(doc.data());
          console.log($venueOrder)
          //tableInfo=doc.data();
        }
  });
}

function setupBot(){
    // Show bot
    showBot=true;
    /*
    f7.$('.venue-page-content').on('scroll',(e)=>{
        let top=e.srcElement.scrollTop;
        console.log(top)
        if (top > 100){
            showBot=true;
        }
    })
    */
}

function GetVenueBeers(){

}

function GetVenueWine(){
  loadingWine=true;
    let locDocRef=$firestoreInstance.collection(`locations/${venue.id}/drinks`);
    let ref=locDocRef.doc('wine')
     ref.get()
    .then((d)=>{
      loadingWine=false;
      if (d.exists) return d.data();
      else return null;
    })
    .then((d)=>{
      if (d)
        venue.wine=d.list;
    });

}
function GetVenueShots(){
    let locDocRef=$firestoreInstance.collection(`locations/${venue.id}/drinks`);
    let ref=locDocRef.doc('shots')
     ref.get()
    .then((d)=>{
      if (d.exists) return d.data();
      else return null;
    })
    .then((d)=>{
      if (d)
        venue.shots=d.list;
    });

}

function GetDrinks(type){

    let locDocRef=$firestoreInstance.collection(`drinks`);
    let dref=locDocRef.doc(type)
     dref.get()
    .then((d)=>{
      if (d.exists) return d.data();
      else return null;
    })
    .then((d)=>{
      if (d){
        console.log("drinks",d)
        venue[type]=d.list;
        //console.log(d.list)
      }
    });
}

function loadAlcoholList(){
	//loading=false;
	let loadAgain=false;
  let brands={};

	let alcoholList=$venueAlcoholList
	if (alcoholList){
    console.log("alc list",alcoholList)
	  if (alcoholList.lid != venue.id) loadAgain=true;
	  else
	   venue.brands=alcoholList;
	}else{
	  loadAgain=true;
	}
   if (loadAgain){
	 let locDocRef=$firestoreInstance.collection(`locations/${venue.id}/alcohol`);
	 let tref=locDocRef.doc('tequila')
	 let vref=locDocRef.doc('vodka')
	 let gref=locDocRef.doc('gin')
	 let rref=locDocRef.doc('rum')
   let sref=locDocRef.doc('scotch')
      let wref=locDocRef.doc('whiskey')
	 brands.lid=venue.id;
	 tref.get()
	 .then((d)=>{
	   if (d.exists) return d.data();
	   else return null;
	 })
	 .then((d)=>{
	   brands.tequila=d;
	 })
	 vref.get()
	 .then((d)=>{
	   if (d.exists) return d.data();
	   else return null;
	 })
	 .then((d)=>{
	   brands.vodka=d;
	 })
	 gref.get()
	 .then((d)=>{
	   if (d.exists) return d.data();
	   else return null;
	 })
	 .then((d)=>{
	   brands.gin=d;
	 })
   rref.get()
	 .then((d)=>{
	   if (d.exists) return d.data();
	   else return null;
	 })
	 .then((d)=>{
	   brands.rum=d;
 
   })
   sref.get()
	 .then((d)=>{
	   if (d.exists) return d.data();
	   else return null;
	 })
	 .then((d)=>{
	   brands.scotch=d;
 
	 })
   wref.get()
	 .then((d)=>{
	   if (d.exists) return d.data();
	   else return null;
	 })
	 .then((d)=>{
	   brands.whiskey=d;
     venueAlcoholList.set(brands);
     venue.brands=brands;
	 })
   }
 
 }
 

function GetTequila(){
  GetDrinks('tequila')
}
function GetVodka(){
  GetDrinks('vodka')
}
function GetRum(){
  GetDrinks('rum')
}
function GetGin(){
  GetDrinks('gin')
}
function GetWhiskey(){
  GetDrinks('gin')
}

function loadData(place){
  let vt={};

  vt.name=place.data.name;
  vt.dataLoaded=false;
  vt.id=place.id
  vt.brands={};
  vt.wine=[];

  venue=vt;

  vt.server = {
    name: 'Jane Queen',
    title: 'Server/Super drink runner',
    pic: user2,
    rating:'4.6'
  }
  vt.cocktails={};

  //setTimeout(()=>{
  //f7.preloader.show('green')
//
 // },100)

  if ($featuredCocktails == null){
     searchDrinks('popularCocktails')
    .then((drinks)=>{
        if (drinks){
           featuredCocktails.set(drinks);
           venue.cocktails.featured=drinks
        }
     });
  }else{
    venue.cocktails.featured=$featuredCocktails;
  }
  if ($popularCocktails == null){
    searchDrinks('popularCocktails')
    .then((drinks)=>{
            if (drinks){
                popularCocktails.set(drinks);
                venue.cocktails.popular=drinks;
            }
    })
    .catch((e)=>{
      console.log("EEOEOE",e)
      //f7.dialog.alert("We are having some issue trying to retrieve drinks. Please check again in a few minutes.");
     //f7.preloader.hide();
    })
  }else{
    venue.cocktails.popular=$popularCocktails;
  }
  if ($latestCocktails == null){
    searchDrinks('latestCocktails')
    .then((drinks)=>{
            if (drinks){
               latestCocktails.set(drinks);
              venue.cocktails.latest=drinks;
            }

    })
    .catch((e)=>{
        console.log(e)
    });

  }else{
    venue.cocktails.latest=$latestCocktails;
  }

  loadAlcoholList();


}

 function onPageInit(){
    let id = f7route.params.id;
    let place=$places[id];
    loadData(place);
  
    // Get Active table event
    if ($qrScanConfirmed)
      setupActiveTableEvent(place.id)

    setupBot();

  }


onMount(() => {
    console.log("on mount")
    f7ready(() => {
      
      /*
     tick().then(()=>{
         console.log("user",$user)
          if ($user){
            userName=$user.displayName
          }
          showBot=true;
        
      })
      */

      /*
      f7.$('.page-content').on('scroll',(e)=>{
        let top=e.srcElement.scrollTop;
        if (top > 33){
           //f7.$('.venue-navbar .link.icon-only i').removeClass("dark")
          f7.$('.navbar.venue-nav').removeClass("navbar-large");
        }else{
          f7.$('.navbar.venue-nav').addClass("navbar-large");
        }
    })
*/

      eDuration=formatDistanceToNow(hhEnd);

      
    
    });

});

</script>