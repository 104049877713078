
{#if show}
<span>{llval}</span>
{/if}

<Popup id="popup-location" tabletFullscreen={true} >
    <View>
      <Page>
        <Navbar title={titlePopup}>
          <NavRight>
            <Link popupClose>Close</Link>
          </NavRight>
        </Navbar>
        <div class="center-wrapper">
            <div class="center-content">
                <Icon material="near_me" color="default" size="128" class="elevation-4" />
                <p>{whyLocation}</p>
                <Row>
                <Col><Button outline href="#skl" popupClose>Skip</Button></Col>
                <Col><Button fill on:click={allowLoc}>Allow</Button></Col>
                </Row>
            </div>
        </div>
      </Page>
    </View>
</Popup>

<svelte:head>
{#if geofirestore}
    <script src="https://unpkg.com/geofirestore/dist/geofirestore.js" defer on:load={onGeoFirestoreLoaded}></script>
{/if}
</svelte:head>
<style>
.center-wrapper {
  margin-top: 25%;
}
.center-content {
 position: relative;
 text-align:center;
 width:80%;
 margin: auto;
 font-size: 15px;
}
</style>
<script>
import { onMount,onDestroy,createEventDispatcher } from 'svelte';
import {updatePosition,updatingPosition,requestPermission,position,lastPosition,gotPosition} from './store'
import {f7,f7ready,Popup,View,Page,Navbar,NavRight,Link,Block,Icon,Row,Col,Button} from 'framework7-svelte'
import {css} from 'emotion/dist/emotion.umd.min.js';
 const dispatch = createEventDispatcher();
export let show=false
//export let cached=true;
export let geofirestore=false;
export let auto=false;
export let cacheAge=3; // Minutes
export let minExpire=4; 
export let whyLocation="This app uses your location to improve the experience.";
export let titlePopup;

let locInterval;


let llval=''

function allowLoc(){
     updatePosition.set(true)
}

function onGeoFirestoreLoaded(){
    console.log("-*-* GEO-FIRESTORE LOADED -*-*-")
    geoFirestoreLoaded.set(true);
}

function success(pos){
    if ($position != null) $lastPosition=$position
    

     updatingPosition.set(false);
    let dexp = new Date(); dexp.setMinutes(dexp.getMinutes() + minExpire);

    position.set({lat: pos.coords.latitude,lng:pos.coords.longitude,exp: dexp});
    //console.log("last pos",$lastPosition)
    if (!$gotPosition)
        gotPosition.set(true);

    llval=`Lat: ${pos.coords.latitude}, Long: ${pos.coords.longitude}`
    window.setTimeout(() => {
        f7.popup.close("#popup-location");
        
    }, 200);


}
function error(err){
    f7.popup.close("#popup-location");

    updatingPosition.set(false);
    alert(`ERROR(${err.code}): ${err.message}`);
}




$: if ($updatePosition){

          updatingPosition.set(true);
            if (navigator.geolocation){
                
                    // Possible loc cache for 4 minutes
                let age=cacheAge * 60 * 1000;
                navigator.geolocation.getCurrentPosition(success,error,{maximumAge: age,enableHighAccuracy: true})
            }else
            alert("No location service")

            updatePosition.set(false)
    // Flag we are updating position.
  
}

onMount(() => {
    console.log("-*-* Location-*")
    locInterval=setInterval(()=>{
        if ($position){
            if ($position.exp < new Date()){
                console.log("-*-*-* UPDATE POS *************")
                updatePosition.set(true);
            }
        }
    },minExpire * 60 * 1000);

    if (auto){
        updatePosition.set(true)
    }
});
onDestroy(()=> clearInterval(locInterval));

  const locCSS= css`
   display: table;
  text-align: center;
    div.lcontent {
         display: table-cell;
  vertical-align: middle;
    }
  `;

</script>