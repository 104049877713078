<Page name="home" hasToolbar ptr ptrDistance={30} onPtrRefresh={refreshLoc} on:pageInit={onPageInit}>
  <!-- Top Navbar -->
  <Navbar sliding={false}>
    <NavTitle sliding>{f7.name}</NavTitle>
    <NavRight>
      <Link iconIos="f7:sun_max" iconAurora="f7:sun_max" iconMd="f7:sun_max" on:click={nightMode}  />
       <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="right" />
    </NavRight>
  </Navbar>

 
 
  {#if home}
   {#if !home.places}
      <Block class="ctr" inset>
        <Icon material="near_me_disabled" size="64"></Icon>
        <p>You have no {f7.name} locations near you right now.</p>
      </Block>
      <Block>
        <Button fill color="green" raised tabLink="#view-explore" tabLinkActive>Explore Drinks</Button>
      </Block>
    {:else}
         <CardImg id="0" mediumImg imgUrl={'https://img.imageboss.me/justdrinks/height/200/venue/' + $place.data.pic} height="200px" bottomHeadingTitle={`Drinking at ${$place.data.name}`} on:cardClicked={(e)=> {goDetail(e.detail.id)}}>
            <div slot="cardContent">
              <Row>
                <Col><Button fill href="#" color="blue" on:click={scanCode} ><Icon f7="qrcode" size="24"></Icon>&nbsp; Scan table</Button></Col>
                <Col><Button fill href="#" on:click={(e)=> {goDetail(0)}}>See drink menu</Button></Col>
              </Row>
            </div>
         </CardImg>
          {#if home.places.length > 1}
              <BlockTitle>Other places</BlockTitle>
              <List mediaList>
              {#each $places as p,i}
                {#if i > 0}
                  <ListItem title={p.data.name} on:click={() => goDetail(i)}>
                    <img slot="media" src={'https://img.imageboss.me/justdrinks/height/200/venue/' + p.data.pic} width="85" class="lazy lazy-fade-in" alt="" />
                    <div slot="after"><KToM kilo={p.distance}></KToM></div>
                  </ListItem>
                {/if}
              {/each}
              </List>
          {/if}
    {/if}
  {/if}
  
</Page>
<style>

</style>
<script>

import sol from '../assets/solyluna_small.jpg'
import ts from '../assets/tequila-sunrise-small.png'
import football from '../assets/football-small.png'
import beer from '../assets/beer.png'
import wine from '../assets/wine-small.png'
import hostess from '../assets/girl-drink.png'
import fetch from 'whatwg-fetch'
import {updatingPosition,position,gotPosition,lastPosition,requestPermission} from '../components/dts-shared-components/location/store'
import {place,places,hideMainToolbar,loadingData,appInitialized,lastScannedVenue,lastScannedCode,qrScanConfirmed,userInfo,venueOrder} from '../js/store'
import {user} from '../components/dts-shared-components/auth/store'
import CardImg from '../components/dts-shared-components/card-img-back.svelte'
import {getNearbyPlaces,addPlace} from '../js/core'

import {formatDistanceToNow,add} from 'date-fns'
import KToM from '../components/dts-shared-components/kilotomile.svelte'
import {
    f7,
    f7ready,
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavTitleLarge,
    NavRight,
    Link,Messagebar,Icon,Messages,Message,MessagesTitle,
    Toolbar,
    Block,
    BlockTitle,
    List,Chip,
    ListItem,Card,CardContent,CardHeader,SkeletonBlock,
    Row,Popup,Menu,MenuItem,MenuDropdown,MenuDropdownItem,Searchbar,
    Col,Fab,FabButtons,FabButton,FabBackdrop,CardFooter,
    Button,PhotoBrowser
  } from 'framework7-svelte';

let home=null



let updated=true;
  let voices = window.speechSynthesis.getVoices();

  let categories=['Popular','Margaritas','Tropical','Keto','Fruity','Virgin'];

 let bartenders=[
    {name:'Tom',lang:'en-US',voice: 0},
    {name:'Jane',lang:'en-US',voice: 4},
    {name:'Kyle',lang:'en-US',voice: 5},
    {name:'Maria',lang:'es-US',voice: 6}
 ]

 let bartender=bartenders[1];
 let btitle=bartender.name + " the bartender";
let catListOpened=false;
let categoryName='Popular Cocktails'
let lastCat
let catLoading=false;
let noData=true;
let gotData=false;
let night=false;



function nightMode(){
  let ui=$userInfo;
  ui.themeDark=!ui.themeDark;
  userInfo.set(ui);

}

function refreshLoc(done){
  gotData=false;
  getNearbyPlaces($firestoreInstance,$position.lat,$position.lng,5.0,3)
  .then((data)=>{
            if (data){
              gotData=true;
              place.set(data[0]);
              places.set(data);
            loadingData.set(false);
            }
            done();
  })
  .catch(()=>{
    loadingData.set(false);
    done();
  })
   window.setTimeout(()=>{
     if (!gotData){
        loadingData.set(true);
     }
  },200)
}

  /*
  var speech = new SpeechSynthesisUtterance(text)
  speech.voiceURI = this.dfconfig.app.voice
  speech.voiceURI='native'
   speech.lang = 'en-US'
   speech.lang='es'

   window.speechSynthesis.speak(speech);
   voice of Text-To-Speech; for reference, see: https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesisVoice/voiceURI
  */

let session='';
let logging=false;
let fabDrinks;


let started=false,response=null;
import { onMount, onDestroy,tick } from 'svelte';
import {initStart,sendToBot } from '../components/dts-shared-components/chatbot/scAPI.js'
import {firestoreInstance,firebaseLoaded}  from '../components/dts-shared-components/firebase/store.js'

import {getCollection} from '../components/dts-shared-components/firebase/fbAPI.js'
let firestore;
let popular=[];

let eDuration;
let hhEnd=add(new Date(),{hours: 2});


function loadData(){
  return new Promise( (resolve,reject) => {
    if (!$places){
      let lat=$position.lat;
      let lng=$position.lng;

      getNearbyPlaces($firestoreInstance,lat,lng,5.0,3)
      .then((data)=>{
          console.log("home data",data)
          if (data.length)
            place.set(data[0]);

          places.set(data);
          resolve(data);
      })
      .catch((e)=>{
        console.error(e)
        f7.dialog.alert("Uh oh. Something went wrong. Please try again in a few minutes.")
        reject();
      })

    }else{
      resolve($places);
    }
  });

  
}
function activateTable(tableData){
  let dbRef=$firestoreInstance.collection(`activeTables/${tableData.lid}/tables`);
  return dbRef.doc(tableData.tableNo).get()
  .then((doc)=>{
    if (doc.exists){
      let data=doc.data();
      if (data.ownerId !== $user.uid){
          // Guest user, add to list
          let users=data.users
           let fi=users.findIndex((u)=>{
                    return u.id == $user.uid
                })
           if (fi > -1){
              users[fi].name=$user.displayName
              users[fi].pic=$user.photoURL
           }else{
             data.users.push({id: $user.uid, name: $user.displayName, pic: $user.photoURL})
           }
          dbRef.doc(tableData.tableNo).set(data,{merge:true})
      }
      return data;
    }else{
      // activate table
      let table={ ownerId: $user.uid, ownerName: $user.displayName, users: [{ id: $user.uid, name: $user.displayName, pic: $user.photoURL}]};
      return dbRef.doc(tableData.tableNo).set(table)
      .then(()=>{
        return table;
      })
          
    }
  })
}

function processCode(qr){

}

function scanCode(){
   //let qr=processCode("1000")
   /* QR Scan sim */
let locCodes=$place.data.qrCodes;
   let data={lid: $place.id,tableNo: locCodes[0].tableNo};
   lastScannedVenue.set(data);

    activateTable(data).then((d)=>{
      qrScanConfirmed.set(true);
      f7.views.main.router.navigate("/venue/0/");
    })

}

function checkQRScan(){
 /* check if qr code scanned and valid */
 let code=window.sessionStorage.getItem("gotQRC");
 //let code="lWPc";
 if (code == null){
   return;
 }else{
   f7.dialog.alert(`got code ${code}`)
 }
 window.sessionStorage.removeItem("gotQRC");
 lastScannedCode.set(code)
 let locCodes=$place.data.qrCodes;
      qrScanConfirmed.set(false);

 if (locCodes){
   // check if code is valid for this location
    let fi=locCodes.findIndex((o)=>{
      return o.code === code
    });
    if (fi > -1){
      let data={lid: $place.id,tableNo: locCodes[fi].tableNo};
     lastScannedVenue.set(data);
      f7.dialog.alert(`QR code for table ${locCodes[fi].tableNo} is valid`);
      if ($place.distance > 0.044){
          console.log($place.distance)
            f7.dialog.alert("You do not appear close enough to venue to order. Please move closer!")
      }else{
         activateTable(locCodes[fi]).then((d)=>{
                       // if (d.ownerId !== $userInfo.id){
                       //   f7.dialog.alert(`This table is already in use. Please notify the server.`);
                       // }else{
          f7.dialog.alert(`You have joined the table.`);
          console.log("SECURED TABLE!!",d)
          qrScanConfirmed.set(true);

          // Clear last venue order (if any)
          venueOrder.set(null);
          f7.views.main.router.navigate("/venue/0/");

                        //}
          })
      }
    }else{
        f7.dialog.alert(`QR code is not valid.`);
    }
 }
   
}

function onPageInit(){
  let ht={};


  loadData()
  .then((d)=>{
    ht.places=d;

      checkQRScan();
   
    home=ht;

  })
  eDuration=formatDistanceToNow(hhEnd);
  //addPlace($firestoreInstance,"On the 30","14622 Ventura Blvd #112","Sherman Oaks","CA","onthe30.jpg",34.151247,-118.452073)
  
    //home=ht;

}

onMount(() => {
console.log("home mounted")
    f7ready(() => {
  
    });
});

function goDetail(index){
  
   place.set($places[index]);
   
   tick().then(()=>{
    f7.views.main.router.navigate(`/venue/${index}/`);

   })
}
 
 
 
  

function openBartender(){
popupBartenderOpened=true;
}


</script>