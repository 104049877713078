<Row>
{#if !loading}
{#each drinks as d,i}
   <Col width="50">
   {#if !d.internal}
    <CardImg height="150px" cornerRadius="8px" id={i} content={false} imgUrl={'https://img.imageboss.me/cocktails/height/150/' + d.pic} bottomHeadingTitle={d.name} on:cardClicked={(e)=> {viewDetails(e.detail.id)}}>
    </CardImg>
   {:else}
    <CardImg height="150px" cornerRadius="8px" id={i} content={false} imgUrl={'https://img.imageboss.me/justdrinks/height/150/' + d.pic} bottomHeadingTitle={d.name} on:cardClicked={(e)=> {viewDetails(e.detail.id)}}>
      </CardImg>
   {/if}
   </Col>
{/each}
{:else}
{#each dummy as d,i}
 <Col width="50">
  <CardImg loading={true} height="150px" cornerRadius="8px" content={false}></CardImg>
 </Col>
{/each}
{/if}
</Row>
<script>
export let loading=true
export let drinks=[];
let dummy=[1,2,3,4,5,6];
import CardImg from './dts-shared-components/card-img-back.svelte'

$: if (drinks && drinks.length){
  console.log("got drinks",drinks)
}

 import { f7,f7ready, Page,Link,Button,Popup,NavRight,CardFooter,Row,Col,PhotoBrowser,Navbar, BlockTitle, Block,Card,CardHeader,CardContent,SkeletonBlock,Chip } from 'framework7-svelte';
 import { createEventDispatcher,tick } from 'svelte';

 const dispatch = createEventDispatcher();
   
 function viewDetails(i){
     dispatch('openDetails', {
      drink: drinks[i],
      index: i
	});
 }

</script>