
let apiUrl="https://test.serve.chat";
import 'whatwg-fetch'
let module,session;

//*********************************************************************************************
//** InitStart
//**
//*********************************************************************************************
let started=false;
export async function initStart(cmodule,action,name,locid,userid,metaData,logging){
	if (!cmodule || !action){
		console.warn("** module and action required ***")
	}else{
		module=cmodule;
        if (started) return; 

		let body={module: cmodule,action: action, name: name};

		if (userid) body.userid=userid;
		if (locid) body.locid=locid;
		if (metaData) body.metaData=metaData;

		if (logging == "true")
			console.log("start.body=" + JSON.stringify(body));
		//if (!welcome){
			const res=	await fetch(`${apiUrl}/api/v1/start`,
			{method:"POST",headers:{"Content-Type":"application/json"},
			body:JSON.stringify(body)});
			let welcome=await res.json();
			if (logging == "true"){
				console.log("initStart, d=" + JSON.stringify(welcome));
            }
            
		//}
			
		return welcome;
	}
}

//*********************************************************************************************
//** sendToBot - Send message to bot handler
//**
//*********************************************************************************************
export async function sendToBot(cmodule,session,msg){

    let body={module: module,session: session,text:msg};

	const res=	await fetch(`${apiUrl}/api/v1/messages`,
	{method:"POST",headers:{"Content-Type":"application/json"},
	body:JSON.stringify(body)});
	const response=await res.json();
	return response;
}

