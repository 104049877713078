{#if !loading}
  {#if !tabLink}
    <figure class="fig-featured" on:click={() => clickF(id)}>
        <img class="img lazy-fade-in" style="--fwidth:{w}; --fheight:{h}" class:circle={circle} class:lazy={lazy} src={pic} alt={title}>
        <figcaption style="--fwidth:{w};">{title}</figcaption>
    </figure>
  {:else}
     <figure class="fig-featured" on:click={() => clickF(id)}>
        <img class="img lazy-fade-in" style="--fwidth:{w}; --fheight:{h}" class:circle={circle} class:lazy={lazy} src={pic} alt={title}>
        <figcaption style="--fwidth:{w};">{title}</figcaption>
    </figure>
  {/if}
{:else}
<!-- loading..-->
<figure class="fig-featured">
    <SkeletonBlock style="width: {w}; height: {h}; border-radius: 50%" class="skeleton-effect-fade" />
    <figcaption style="--fwidth:{w}" class="skeleton-text skeleton-effect-fade">xxxxxxxxxx</figcaption>
</figure>
{/if}

<style>
.fig-featured .img {
  object-fit: cover;
  width: var(--fwidth);
  height: var(--fheight)
}
.img.circle {
    border-radius:50%;
    border: 2px solid #c0c0c0;
    box-shadow: var(--f7-theme-circle-shadow);
}
.swiper-slide-active .fig-featured .img.circle {
  border: 3px solid #eab455;
}

.fig-featured figcaption {
  width: var(--fwidth);
  font-weight: bold;
  text-align:center;
}
</style>
<script>
import { createEventDispatcher,onMount } from 'svelte';
import {SkeletonBlock} from 'framework7-svelte'
const dispatch=createEventDispatcher();

export let title;
export let pic;
export let id="0";
export let circle=true;
export let h="100px"
export let w="100px"
export let lazy=false;
export let loading=false;
export let tabLink=false;
export let link="#abc";


function clickF(){
    dispatch('featureClicked',{ id: id});
}
</script>
