
import HomePage from '../pages/home.svelte';
import SplashPage from '../pages/splash.svelte';
import LocPage from '../pages/loccheck.svelte';
import DrinksPage from '../pages/drinks.svelte';
import AlcoholPage from '../pages/alcohol.svelte';
import ExplorePage from '../pages/explore.svelte';
import VenuePage from '../pages/venue.svelte';
import FeaturedPage from '../pages/featured.svelte';
import AboutPage from '../pages/about.svelte';
import ProfilePage from '../pages/profile.svelte';
import MorePage from '../pages/more.svelte';
import FormPage from '../pages/form.svelte';
import AlertsPage from '../pages/alerts.svelte';
import CatalogPage from '../pages/catalog.svelte';
import ProductPage from '../pages/product.svelte';
import SettingsPage from '../pages/settings.svelte';
import QRCRecPage from '../pages/qrcreceive.svelte';
import DynamicRoutePage from '../pages/dynamic-route.svelte';
import RequestAndLoad from '../pages/request-and-load.svelte';
import NotFoundPage from '../pages/404.svelte';
import CSPage from '../pages/parked.svelte';
import PWT from '../pages/walkthrough.svelte';
import TP from '../pages/testpage.svelte';

import {handleAsyncLogin } from '../components/dts-shared-components/auth/core'




var routes = [
  {
    path: '/',
    //component: SplashPage,
    name:'homeroot',
    async: function(routeTo,routeFrom, resolve,reject) {
      var router = this;
      var app = router.app;
      app.$('.main-toolbar').addClass('toolbar-hidden');
      //   console.log("qr aync, route to");
        let c=routeTo.query.c;
        if (c != null){
            window.history.pushState({}, app.name, "/");    
            window.sessionStorage.setItem("gotQRC",c)
            resolve({component: SplashPage});
            //handleAsyncLogin(app,resolve,reject,SplashPage);
        }else{
          //handleAsyncLogin(app,resolve,reject,SplashPage);
           resolve({component: SplashPage});
        }
    }
   // async: function(routeTo,routeFrom, resolve,reject) {
   //   console.log("home aync, route to");
   //   console.log(routeTo.query)
   //   resolve();
   // },
   /*
    beforeEnter: function(to, from, resolve, reject) {
      var router = this;
      var app = router.app;
      let gotLoc=localStorage.getItem('gotLoc');
      
      if (gotLoc && gotLoc == "true"){

        resolve();
      }else{
        reject();
        router.navigate('/loc/');
        app.$(".main-toolbar").hide();
        return;
      }
    }
    */
  },
  {
    path: '/qr/:id/',
    //component: QRCRecPage,
    //url:"http://localhost:5000/qr/?c={id}"
    //name: 'home',
    //component: HomePage,
    async: function(routeTo,routeFrom, resolve,reject) {
    //   console.log("qr aync, route to");
     console.log(routeTo.query)
      resolve({componentUrl: '/qr/1'});
    },
  },
  {
    path: '/home/',
    name: 'home',
    async: function(routeTo,routeFrom, resolve,reject) {
      var router = this;
      var app = router.app;
      handleAsyncLogin(app,false)
      .then((loggedIn)=>{
        if (loggedIn){
          resolve({component: HomePage},{reloadCurrent: true});
          return;
        }else
          resolve({component: PWT},{reloadCurrent: true});
      },(e)=>{
        reject();
      })
      .catch((e)=>{
        console.log("error",e)
        reject();
      })
      /*
        let c= window.sessionStorage.getItem("gotQRC")
        if (c != null){
          window.sessionStorage.removeItem("gotQRC")
          // validate QR code
          let fs=new firebase.firestore();
          let qrcodeRef=fs.collection('qrCodes').doc(c);
          qrcodeRef.get()
          .then((doc)=>{
            if (doc.exists){
              let data=doc.data();
              window.sessionStorage.setItem("JD_QRC",JSON.stringify(data));
            }
            resolve({component: HomePage});
          })
          .catch(()=>{
            resolve({component: HomePage});
          });
        }else
          resolve({component: HomePage});
      */
    }
    //component: HomePage,
    
  },
  {
    path: '/coming/',
    name: 'comingsoon',
    component: CSPage
  },
  {
    path: '/more/',
    name: 'settings',
    component: MorePage
  },
  {
    path: '/profile/',
    name: 'profile',
    component: ProfilePage
  },
  {
    path: '/alerts/',
    name: 'alerts',
    component: AlertsPage,
    
  },
  {
    path: '/splash/',
    name: 'splash',
    component: SplashPage,
  },
  {
    path: '/testpage/',
    name: 'testpage',
    component: TP,
  },
  {
    path: '/walkthrough/',
    name: 'walkthrough',
    component: PWT,
  },
  {
    path: '/loc/',
    name: 'loc',
    component: LocPage,
  },
  {
    path: '/featured/',
    component: FeaturedPage,
  },
  {
    path: '/venue/:id/',
    component: VenuePage,
    name: 'venue'
  },
  {
    path: '/drinks/:cat/',
    component: DrinksPage,
  },
  {
    path: '/alcohol/',
    component: AlcoholPage,
  },
  {
    path: '/explore/',
    component: ExplorePage,
  },
  {
    path: '/catalog/',
    component: CatalogPage,
  },
  {
    path: '/product/:id/',
    component: ProductPage,
  },
  {
    path: '/settings/',
    component: SettingsPage,
  },

  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
