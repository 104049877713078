<span>{miles} mi</span>
<script>
    import { onMount } from 'svelte';

export let kilo=0;
let miles=0;
let kl=kilo; // this is a weird thing that must be done for component to be reactive in loops


 onMount(() => {
     console.log("mounted kilo",kl)
     miles=kl*0.62137119223;
     miles=miles.toFixed(2);
 });

</script>
