<Page noNavbar name="comingsoon" class="page-coming" onPageInit={onPageInit} onPageBeforeRemove={onPageBeforeRemove} noToolbar>

<div class="block text-align-center">
<img src="https://img.imageboss.me/justdrinks/width/175/logo_transparent_background.png" alt="" />
				<p class="coming-soon-title">
                Coming to a bar near you.</p>
			</div>

			<div class="block">
				<div class="countdown">
					<div class="countdown-days">
						<div class="countdown-value">{countdownD.days}</div>
						<div class="countdown-label">DAYS</div>
					</div>
					<div class="countdown-hours">
						<div class="countdown-value">{countdownD.hours}</div>
						<div class="countdown-label">HOURS</div>
					</div>
					<div class="countdown-minutes">
						<div class="countdown-value">{countdownD.minutes}</div>
						<div class="countdown-label">MINUTES</div>
					</div>
					<div class="countdown-seconds">
						<div class="countdown-value">{countdownD.seconds}</div>
						<div class="countdown-label">SECONDS</div>
					</div>
				</div>
			</div>

			<div class="block">
				<button type="button" class="button button-big button-fill button-round" on:click={subscribe}>Notify Me</button>
			</div>

			<div class="block">
				<a href="https://facebook.com/justdrinksapp" target="_blank" class="external">
					<i class="fa-stack">
						<span class="fas fa-circle fa-stack-2x brand-color-facebook"></span>
						<span class="fab fa-facebook-f fa-stack-1x fa-inverse"></span>
					</i>
				</a>
                <a href="https://instagram.com/justdrinksapp" target="_blank" class="external">
					<i class="fa-stack">
						<span class="fas fa-circle fa-stack-2x brand-color-instagram"></span>
						<span class="fab fa-instagram fa-stack-1x fa-inverse"></span>
					</i>
				</a>
			</div>
</Page>
<style>
:global(.page-coming .page-content) {
		align-items: stretch;
		background-color: var(--oc-color-gray-9);
		background-image: url('https://img.imageboss.me/justdrinks/width/800/guy-at-bar2.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
		text-align: center;
	}

:global(.page-coming .page-content > :last-child) {
		margin-top: auto;
	}

	.block {
		color: #FFFFFF !important;
	}

	.coming-soon-title {
		font-size: 36px;
		font-weight: bold;
        text-align: center;
        margin:0
	}
	.countdown {
		display: flex;
		justify-content: space-around;
		margin: 0 auto;
		max-width: 640px;
		text-align: center;
	}

	.countdown-value {
		font-size: 36px;
		font-weight: bold;
	}

	.countdown-label {
		font-size: 12px;
	}

	.button {
		margin: 0 auto;
		width: 256px;
	}

</style>

<script>
  import { f7,f7ready,Page, Navbar, Block, BlockTitle } from 'framework7-svelte';

let endDate= new Date(2020, 9, 15);
let timerId;
let countdownD={
    	days: 0,
	    hours: 0,
		minutes: 0,
	    seconds: 0
}
function subscribe(){
f7.dialog.prompt(
					'Please enter you email address.',
					'Subscribe',
					function(value) {
						if (value != '') {
							f7.toast.show({
								text: 'Thank you for subscribing.',
								position:'bottom',
								cssClass: 'toast-round bg-color-green'
							});
						}
					}
				);
}
function onPageBeforeRemove(){
    window.clearInterval(timerId);
}

function initializeCountdown() {
    timerId = countdown(endDate, function(timespan) { updateCountdown(timespan); }, countdown.DAYS|countdown.HOURS|countdown.MINUTES|countdown.SECONDS);
}

function updateCountdown(timespan) {
    var countdownData = {
        days: timespan.days,
        hours: timespan.hours,
        minutes: timespan.minutes,
        seconds: timespan.seconds
    };
    countdownD=countdownData
}
function onCountdownLoaded(){
     initializeCountdown();
}
function init(){
    //https://cdnjs.cloudflare.com/ajax/libs/countdown/2.6.0/countdown.min.js
  let script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://cdnjs.cloudflare.com/ajax/libs/countdown/2.6.0/countdown.min.js";
            script.onload=onCountdownLoaded;
            document.body.appendChild(script);
}

  function onPageInit(){
      init();

      f7ready(()=>{
          f7.$('.toolbar').addClass('toolbar-hidden');
      });
  }
</script>