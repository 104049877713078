<Page name="explore">
  <Navbar title={f7.name} />

  <BlockTitle medium>Explore your perfect drink</BlockTitle>
  <Block>Choose a category to help find your perfect cocktail or ask our hostess bot below.</Block>
  <List mediaList id="per-drinks" inset>
     <ListItem title="Popular" link="/drinks/Popular/">
      <div slot="media"  class="i">
      <div class="drink-img" id="drink-margarita"></div>
      </div>
    </ListItem>
     <ListItem title="Fruity" link="/drinks/Fruity/">
      <div slot="media" class="i">
      <div class="drink-img" id="drink-popular" style="margin-left:9px"></div>
      </div>
    </ListItem>
      <ListItem title="Virgin" link="/drinks/Virgin/">
       <div slot="media" class="i">
        <div class="drink-img" id="drink-keto" style="margin-left:10px"></div>
      </div>
    </ListItem>
  </List>
</Page>
<script>
 import { f7,f7ready, Page,Link, Popup,NavRight,CardFooter,List,ListItem,PhotoBrowser,Navbar, BlockTitle, Block,Card,CardHeader,CardContent,SkeletonBlock,Chip } from 'framework7-svelte';

</script>