import { writable} from 'svelte/store';
import { writable as pwritable } from 'svelte-persistent-store/dist/local';

export const geoFirestoreLoaded=writable(false);

export const updatePosition=writable(false);
export const updatingPosition=writable(false);
export const hideMainToolbar=writable(false);

export const requestPermission=writable(false);
export const position=pwritable('uloc',null);
export const gotPosition=pwritable('gotLoc',false);
export const lastPosition=pwritable('luloc',null);