<Page noNavbar noToolbar noSwipeback name="splash" onPageInit={onPageInit}>
  <div class="splash">
    <div class="splash-media">
        <div class="logo">
          <img id="splash" src='static/icons/logo2-192.png' alt={appName}  />
        </div>
    </div>
    <div class="splash-preloader">
		  <span class="preloader preloader-yellow"></span>
	</div>
  </div>
</Page>
<style>
.splash {
		align-items: stretch;
		background-color: #ffffff;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-around;
		overflow: hidden;
		text-align: center;
}
  .ios .splash {
		padding: 0 15px;
	}

	.md .splash {
		padding: 0 16px;
	}

	.ios .splash-media {
		margin: 35px 0;
	}

	.md .splash-media {
		margin: 32px 0;
	}

	.splash-media .logo {
		height: 56px;
		width: auto;
	}

	.splash-media .logo path {
		fill-opacity: 0;
		opacity: 0;
		stroke-width: 5px;
		transition: fill-opacity 1s;
	}

	.splash-media .logo.animation-begin path {
		opacity: 1;
	}

	.splash-media .logo.animation-complete path {
		fill-opacity: 1;
		stroke-width: 0;
	}

	.splash-preloader {
		opacity: 0;
		transition: opacity 1s;
	}

	.ios .splash-preloader {
		margin: 35px 0;
	}

	.md .splash-preloader {
		margin: 32px 0;
	}

	.splash-preloader .preloader {
		height: 32px;
		width: 32px;
	}
</style>
<script>
  import { onMount } from 'svelte';
 import logo from '../assets/drinkup-logo2.png'
  import {f7ready, Page, Navbar, Block,Button,f7 } from 'framework7-svelte';
import { loginStatus,authChanged,openPopup } from '../components/dts-shared-components/auth/store'
import {place,locInitialized,hideMainToolbar,oldUser} from '../js/store'
let appName="";


  
/*
$: if ($locInitialized){
 f7.views.main.router.navigate({ name: 'home' });
}
window.setTimeout(()=> {
    if (!$gotPosition){
       f7.views.main.router.navigate({ name: 'loc' });
    }
},700)
*/
function onPageInit(){
	appName=f7.name;
	showPreloader();
	hideToolbar();
	

	// Immediately try to go to home page (router will delay waiting for all resources to load)
	f7.views.current.router.navigate('/home/');
	

}
onMount(() => {
    f7ready(()=>{
    });
});
function hideToolbar(){
  	setTimeout(function() {
				f7.$('.toolbar').addClass('toolbar-hidden');
		});
}
function showPreloader(){
  	setTimeout(function() {
				f7.$('.splash-preloader').css('opacity', 1);
		}, 1000);
}

</script>